import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PlanetEveryClassroomPage = ({ location }) => {
  return (
    <Layout location={location} title="Planet in every classroom">
      <SEO title="Planet in every classroom" />
      <section id="planet-in-every-classroom-page" className="page">
        <div className="container text-white">
          <div className="content bg-black p-5">
           
            <p>
              This is our most important program since we began this journey.
              Between 1997 and 2005, The EarthSeeds Project donated Earth image
              products and educational materials to recipients in 12 countries
              and throughout the United States. In our home State of Colorado,
              we donated Earth images to rooms that reached tens-of-thousands of
              middle school students, we presented directly in classrooms and at
              school assemblies.
            </p>
            <div class="sketchfab-embed-wrapper">
    <iframe title="A 3D model of EarthSeeds Astronauts Globe" width="90%" height="480" src="https://sketchfab.com/models/6682778edbe14e789d8d7165e9778cd6/embed" frameborder="0" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
            <p>
              “Dear Singing Astronauts, You’re right about everything you said.
              I’ll never kill a dangerous looking bug again. I understand why
              you wore the spacesuit. You’ve convinced me to try and take care
              of our spaceship mother earth and all the crew. Your songs were
              some of the best songs I’ve ever heard. I hope you can always do
              this in each school around the world. Your fan, Derek”
            </p>
            <p>
              Planet in Every Classroom was supported then by our
              interdisciplinary, standards-based EarthSeeds Curriculum Guide and
              the award-winning Astronaut’s Globe. We created over 40 Lesson
              Plans in collaboration with a team of leading educators in
              experiential learning, environmental education, non-violent
              communication, science and Space.
            </p>
            <div className="img-fluid mb-4">
              <img
                src="/assets/img/living-earth2.jpg"
                alt="Kids with Globe in Class"
              />
            </div>
            <p>
              The booklet included with the Astronaut’s Globe was filled with
              fun facts about Earth and a bunch of activities for using the
              Globe to learn and have fun. The booklet was a tiny version of our
              first book, Spaceship Earth: A Crewmember’s Guide published many
              years ago from the heart of a National Park Ranger and former
              Naval Officer who is one of our founders.
            </p>
            <p>
              In 2004 the Astronaut’s Globe received a Parent’s Choice Award for
              Best Educational Toy. We then created our first Planet in Every
              Classroom Kit which combined our best educational resources in one
              convenient, reusable box.
            </p>
                <div className="img-fluid mb-4">
                  <img
                src="/assets/img/kids-with-globe-in-class.jpg"
                alt="Kids with Globe in Class"
                height="250"
                  />
                </div>
            <p>
              Creating new educational materials and experiences to use with 2D
              and 3D Earths is always a priority for us. Billions see the Earth
              from Space image, but how many have the opportunity to really
              connect with the experience and take something valuable and
              personal away from it?
            </p>
            <div className="img-fluid mb-4">
              <img
                src="/assets/img/earthseeds-logo-white.png"
                alt="Kids with Globe in Class"
              />
            </div>
            <p>
              The EarthSeeds Curriculum Guide is still available but you’ll have
              to request a digital copy by contacting us.
            </p>
            <p>
              Sign up to receive Earth Images or education resources for your
              students or group below.
            </p>
            <Link className="btn btn-normal hvr-shadow mb-3" to="/contact">
              EDUCATOR SUBSCRIBE HERE
            </Link>
            <p>
              We ask you to support our new website and fundraising store with
              the epic Fine Art Fundraising program to once again capture the
              hearts and minds of children everywhere. As always, we strive to
              operate Global View on an Administrative budget of 15% or less.
              While we don’t have a current or robust portfolio of outreach
              recipients, we are committed to allocating your donations to
              recipients and communities that are interested in receiving our
              resources and are excited to participate in the new EarthSeeds
              Project!
            </p>
            <p>
              Our focus is executing a strategic plan for the EarthDay 2020
              season which includes outreach development, Earth Day event
              contributions, and a small expansion of merchandise available in
              the store. All of the money we raise in 2019 is funding our
              EarthDay 2020 campaign and outreach recipients for the first half
              of next year. We are also interested in opportunities to leverage
              online, immersive Earth viewing technologies.
            </p>
            <p>
              Our Earth Products fundraising store not only serves our mission
              by helping to expand your life and those around you with
              beautiful, fine art Gallery Wraps of Earth in Space but it’s also
              our primary means of raising money to facilitate our outreach
              programs.
            </p>

            {/*ELEMENT - - FUNDRAISING STORE Featured Product Window
             */}

            <p>
              We’ve helped create and distribute some the nation’s most amazing
              Earth products for home and classroom use. Join us for the new
              generation of The EarthSeeds Project!
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PlanetEveryClassroomPage
